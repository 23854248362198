html,
body,
#root {
  height: auto;
  @media only screen and (max-width: 640px) {
    height: 100%;
  }
}
html,
body,
#root,
div,
label,
button {
  font-family: 'Inter', sans-serif !important;
}
#root {
  min-height: 100%;
  height: 100%;
}
// body {
//   background-color: #fafafa;
//   @media only screen and (min-width: 640px) {
//     background-color: #eeeeee;
//   }
// }
body::-webkit-scrollbar {
  width: 8px; /* Set width of the scrollbar */
}

body::-webkit-scrollbar-track {
  background-color: #3b3b3b; /* Light gray for the track */
  // border-radius: 10px; /* Rounded corners for the track */
}

body::-webkit-scrollbar-thumb {
  background-color: #969696; /* A bit darker gray for the thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
  border: 2px solid #9c9c9c; /* Add padding effect inside thumb */
}
body::-webkit-scrollbar-thumb:hover {
  background-color: #6e6e6e; /* A bit darker gray for the thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
  border: 2px solid #646464; /* Add padding effect inside thumb */
}
// /* WebKit Browsers (Chrome, Safari, Edge) */
// ::-webkit-scrollbar {
//   width: 12px; /* Modern, thinner scrollbar */
// }

// ::-webkit-scrollbar-track {
//   background: var(--scrollbar-track); /* Use CSS variables for themes */
// }

// ::-webkit-scrollbar-thumb {
//   background-color: var(
//     --scrollbar-thumb
//   ); /* Dynamic thumb color based on theme */
//   border-radius: 10px; /* Rounded, modern look */
//   border: 3px solid transparent; /* Transparent border to create space */
//   background-clip: content-box; /* Ensures the rounded edges don't affect border */
// }

// ::-webkit-scrollbar-thumb:hover {
//   background-color: var(--scrollbar-thumb-hover); /* Hover effect */
// }

// /* Firefox */
// html {
//   scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track); /* Thumb & Track color */
//   scrollbar-width: thin; /* Thin modern scrollbar */
// }

// /* Light Theme Scrollbar */
// :root {
//   --scrollbar-track: #e0e0e0; /* Light track color */
//   --scrollbar-thumb: #888; /* Neutral gray for the thumb */
//   --scrollbar-thumb-hover: #555; /* Darker thumb on hover */
// }

// /* Dark Theme Scrollbar */
// @media (prefers-color-scheme: dark) {
//   :root {
//     --scrollbar-track: #2b2b2b; /* Darker track for dark mode */
//     --scrollbar-thumb: #666; /* Medium gray for thumb */
//     --scrollbar-thumb-hover: #888; /* Slightly lighter thumb on hover */
//   }
// }
#parent-container {
  min-height: 100vh;
}
#sideBar-container {
  min-height: 100vh;
}
@media only screen and (min-width: 1600px) {
  #parent-container {
    max-width: 1600px;
  }
  #sideBar-container {
    max-width: 100%;
  }
  #navBar {
    max-width: 1600px;
  }
  .re-entry-parent {
    margin-left: auto !important;
  }
  .parent-ml-auto {
    margin-left: auto !important;
  }
}
@media only screen and (max-width: 1600px) {
  .re-entry-parent {
    margin-left: 0px !important;
  }
  .parent-ml-auto {
    margin-left: 0px !important;
  }
  .lots-parent {
    margin-right: 100px !important;
  }
}

.comman-input {
  height: 56px;
  min-width: 128px !important;
  label {
    font-size: 16px;
    // color: rgba(0, 0, 0, 0.6);
    letter-spacing: 0.15px;
  }
  input {
    height: 56px;
    font-size: 16px;
    box-sizing: border-box;
  }
}

.edit-hover {
  &:hover {
    button {
      opacity: 1;
    }
  }
  button {
    opacity: 0;
  }
}

a {
  text-decoration: none;
  color: inherit;
}
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bs-2 {
  border-bottom-width: 2px;
}
.sticky-top {
  top: 0;
  position: sticky;
  z-index: 1;
  // background-color: #fafafa;
  @media only screen and (min-width: 640px) {
    position: relative;
  }
}
.show-more {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.15px;
  font-family: 'Inter', sans-serif;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  & span.anchor-show-more {
    font-weight: 500;
    font-size: 0.8125rem;
    line-height: 1.75;
    padding: 4px 5px;
    color: rgba(0, 0, 0, 0.87);
    text-transform: none;
    padding-left: 0px;
    text-decoration: none;
    display: flex;
    cursor: pointer;
  }
  & p {
    margin: 0;
  }
  span {
    display: inline;
  }
}

// For graph gradient
.display-less {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
[stroke='url(#graphGradient)'] {
  stroke: #00c853;
}

// For main horizontal spacing screen wise
.p-main-spacing {
  padding-left: 24px !important;
  padding-right: 24px !important;
  @media only screen and (max-width: 639px) {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  @media only screen and (max-width: 351px) {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}

.m-main-spacing {
  margin-left: 24px !important;
  margin-right: 24px !important;
  @media only screen and (max-width: 639px) {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  @media only screen and (max-width: 351px) {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }
}

.ml-main-spacing-md {
  @media only screen and (max-width: 1000px) {
    margin-left: 20px !important;
  }
}

.mr-main-spacing-md {
  @media only screen and (max-width: 1000px) {
    margin-right: 20px !important;
  }
}

.m-main-spacing-sm {
  @media only screen and (max-width: 639px) {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  @media only screen and (max-width: 351px) {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }
}

.select-with-single-input-box {
  flex-direction: row !important;
  border: 1px solid #ccc !important;
  border-radius: 3px !important;
  height: 56px !important;
  min-width: 256px !important;
  max-width: 270px !important;
  width: 100% !important;
  &.error {
    border: 1px solid #f44336 !important;
    label {
      color: #f44336 !important;
    }
  }
  &.full-width {
    max-width: 100% !important;
  }
  .select {
    max-width: 75% !important;
    width: auto !important;
  }
  .text-field {
    // width: auto !important;
    border: none;

    border-left: 1px solid #ccc;
    height: 36px !important;
    margin-top: 10px;
    margin-bottom: 10px;
    input {
      height: 36px !important;
      padding-top: 8px;
      padding-bottom: 8px;
      box-sizing: border-box;
    }
  }
}

.select-with-double-input-box {
  flex-direction: row !important;
  border: 1px solid #ccc !important;
  border-radius: 3px !important;
  height: 56px !important;
  min-width: 288px !important;
  max-width: 288px !important;
  width: 100% !important;
  &.error {
    border: 1px solid #f44336 !important;
    label {
      color: #f44336 !important;
    }
  }
  &.full-width {
    max-width: 100% !important;
  }
  .select {
    max-width: 45% !important;
    width: auto !important;
  }
  .text-field {
    width: 25% !important;
    border: none;
    border-left: 1px solid #ccc;
    height: 36px !important;
    margin-top: 10px;
    margin-bottom: 10px;
    input {
      height: 36px !important;
      padding-top: 8px;
      padding-bottom: 8px;
      box-sizing: border-box;
      width: 72px;
    }
  }
  .multi_text-field {
    border: none;
    border-left: 1px solid #ccc;
    height: 36px !important;
    margin-top: 10px;
    margin-bottom: 10px;
    input {
      height: 36px !important;
      padding-top: 8px;
      padding-bottom: 8px;
      box-sizing: border-box;
    }
  }
}

.multi-select-box {
  display: flex;
  flex-direction: row !important;
  border: 1px solid #ccc !important;
  border-radius: 3px !important;
  height: 56px !important;
  min-width: 192px !important;
  align-items: center;

  &.error {
    border: 1px solid #f44336 !important;
    label {
      color: #f44336 !important;
    }
  }
  .select {
    max-width: 100% !important;
    width: auto !important;
  }
}

.add-input-prefix {
  border-right: 1px solid #ccc;
  min-width: 116px !important;
  max-width: 116px !important;
  width: 116px !important;
  height: auto !important;
  color: rgba(0, 0, 0, 0.87) !important;
  font-size: 16px;
}

.select-option {
  text-transform: capitalize !important;
}

.remove-border {
  border: none !important;
  text-transform: none !important;
}

.toggle-button-group {
  button {
    // background: #f5f5f5;
    width: 128px;
    border: 1px solid;
    // border-radius: 4px 0px 0px 4px;
    // color: rgba(0, 0, 0, 0.6);
    text-transform: none;
    margin-left: 0 !important;
    &.active {
      // background-color: #eeeeee;
      // color: rgba(0, 0, 0, 0.87);
      border: 1px solid;
    }
    &:hover {
      // background: #eeeeee;
      // color: rgba(0, 0, 0, 0.87);
      border: 1px solid;
    }
  }
}

.option-leg-parent {
  // overflow: hidden;
  &:last-child {
    margin-bottom: 8px !important;
  }
  &::before {
    content: '';
    width: 3px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    // background: #e0e0e0;
    -webkit-border-top-left-radius: 10px;
    -webkit-border-bottom-left-radius: 10px;
    -moz-border-radius-topleft: 10px;
    -moz-border-radius-bottomleft: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}

.leg-call {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #2196f3;
}
.leg-put {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #673ab7;
}
.leg-buy {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #4caf50;
}
.leg-sell {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #f44336;
}

.hover-visible {
  display: none !important;
}
.hoverable-row {
  &:hover .hover-visible {
    display: flex !important;
  }
  & td:hover .hover-visible {
    display: flex !important;
  }
}
.extra-label {
  position: absolute;
  right: 2rem;
  height: 100%;
  display: flex;
  align-items: center;
}
.tooltip-container {
  font-style: normal;
  font-size: 13px;
  font-weight: 400;
  background: #2a2e39;
  border-radius: 2px;
  color: #fff;
  display: grid;
  gap: 2px 8px;
  grid-template-columns: 1fr auto;
  line-height: 18px;
  padding: 4px 8px;
  pointer-events: none;
  transform: translateY(-100%);
  z-index: 10;
  box-sizing: border-box;
  opacity: 0.9;
}

.tooltip-container .value {
  display: flex;
  align-items: center;
}

.tooltip-container .currency {
  margin-left: 4px;
}

.tooltip-container .legend {
  display: inline-block;
  margin-right: 4px;
}
.carousel-container {
  width: 100%;
  height: 100%;
  display: flex;
  // justify-content: center;
  // align-items: center;
  // background-color: #141e30;
}

.carousel-slide {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  // height: calc(100vh - 189px) !important;
  // color: white;
  // padding: 8px;
  text-align: start;
  // background: linear-gradient(135deg, #1e3c72, #2a5298);
  background: transparent;
}

.carousel-slide h2 {
  font-size: 32px;
  font-weight: bold;
  margin: 0;
  padding: 20px;
}
