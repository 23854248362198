.recharts-cartesian-axis-tick-line {
  stroke: rgba(0, 0, 0, 0.04);
}

.recharts-cartesian-axis-tick-value {
  font-size: 0.625rem;
}

.recharts-cartesian-grid-horizontal line,
.recharts-cartesian-grid-vertical line {
  stroke: rgba(0, 0, 0, 0.04);
}

.recharts-cartesian-grid-horizontal line:first-child,
.recharts-cartesian-axis line {
  stroke: rgba(0, 0, 0, 0.12);
}
